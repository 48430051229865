import { Box } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useMoralis } from 'react-moralis';
import { LockedPage } from 'src/components/locked-page/LockedPage';
import { StakingLayout } from './StakingLayout';

export const Staking = () => {
  const { user } = useMoralis();

  const _description = 'Stake Liquidity Tokens (LP) to earn!';

  return (
    <>
      <Helmet>
        <title>Staking | Axolittles.io</title>
        <meta name="description" content={_description} />
      </Helmet>
      <Box
        style={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: user ? 'flex-start' : 'center'
        }}
      >
        {user ? <StakingLayout /> : <LockedPage />}
      </Box>
    </>
  );
};
