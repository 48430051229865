import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { memo, useRef } from 'react';
// @ts-ignore
import ReactFreezeframe from 'react-freezeframe';

interface IData {
  data: any;
  handleClick: (event: React.MouseEvent<unknown>, tokenId: string) => void;
  isSelected: (tokenId: string) => boolean;
}

export const NftCard: React.FC<IData> = memo(
  ({ data, handleClick, isSelected }) => {
    const parsedMetadata = JSON.parse(data.metadata);
    const ref = useRef<any>();
    const theme = useTheme();
    const isMobile = useMediaQuery<boolean>(theme.breakpoints.down('md'));

    const isItemSelected = isSelected(data.token_id);

    return (
      <Card
        ref={ref}
        sx={{ width: 265, margin: 'auto', cursor: 'pointer' }}
        onClick={(event) => handleClick(event, data.token_id)}
      >
        <ReactFreezeframe
          src={parsedMetadata?.image}
          options={{
            trigger: isMobile ? false : 'hover',
            overlay: false
          }}
          style={{
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            // This is to get border radius to be the same
            // as the card for IOS Safari.
            borderRadius: '4px 4px 0 0'
          }}
        />

        <CardActions>
          <Checkbox
            checked={isItemSelected}
            onClick={(event) => handleClick(event, data.token_id)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography variant="body1" component="h4">
            {parsedMetadata?.title}
          </Typography>
        </CardActions>
      </Card>
    );
  }
);
