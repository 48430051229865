import { createTheme, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React, { useReducer } from 'react';
import { MoralisProvider } from 'react-moralis';
import { useRoutes } from 'react-router-dom';
import { GlobalStylesComponent } from 'src/components/GlobalStyles';
import { routes } from 'src/routes';
import { GlobalAppContext, initialState, reducer } from 'src/store';
import { Typography } from './theme/typography';

export const App = () => {
  const routing = useRoutes(routes);
  const [state, dispatch] = useReducer(reducer, initialState);

  const theme: any = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: state.isDarkMode ? 'dark' : 'light',
          background: {
            // default: '#F4F6F8',
            // paper: state.isDarkMode ? '#212121' : '#fff'
          },
          primary: {
            // contrastText: '#ffffff',
            main: '#4bb1fe'
          },
          secondary: {
            main: '#C6312E'
          }

          // text: {
          //   primary: '#172b4d',
          //   secondary: '#6b778c'
          // }
        },
        components: {
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: state.isDarkMode ? '#212121' : '#fff'
              }
            }
          },
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'none'
              }
            }
          },
          // Not sure if this is a bug but I need to put this here to enable column sizes for
          // grid items
          MuiGrid: {
            styleOverrides: {
              root: {
                maxWidth: 'unset'
              },
              item: {
                maxWidth: 'unset'
              }
            }
          }
        },
        typography: Typography
      }),
    [state.isDarkMode]
  );

  const moralisAppId = process.env.REACT_APP_MORALIS_APP_ID!;
  const moralisServerUrl = process.env.REACT_APP_MORALIS_SERVER_URL!;

  return (
    <MoralisProvider appId={moralisAppId} serverUrl={moralisServerUrl}>
      <GlobalAppContext.Provider value={{ state, dispatch }}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <ThemeProvider theme={theme}>
            <GlobalStylesComponent />
            {routing}
          </ThemeProvider>
        </SnackbarProvider>
      </GlobalAppContext.Provider>
    </MoralisProvider>
  );
};
