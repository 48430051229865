import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { useMoralis } from 'react-moralis';
import { ActionTypes, GlobalAppContext } from 'src/store';
import { NftContainer } from './NftContainer';

interface IProps {
  setApproval: () => void;
  unstakedNfts: any[];
  reload: () => void;
  loading: boolean;
}

export const Unstaked: React.FC<IProps> = ({
  setApproval,
  unstakedNfts,
  reload,
  loading
}) => {
  const { user } = useMoralis();

  const { enqueueSnackbar } = useSnackbar();

  const { state, dispatch } = useContext(GlobalAppContext);

  const [selectedTokenIds, setSelectedTokenIds] = useState<readonly string[]>(
    []
  );

  const setGlobalLoading = (value: boolean) => {
    dispatch({
      type: ActionTypes.SET_IS_LOADING_GLOBALLY,
      payload: value
    });
  };

  const handleStake = async () => {
    if (!user) {
      enqueueSnackbar('User is not logged in', { variant: 'error' });
      return;
    }

    try {
      setGlobalLoading(true);

      await setApproval();

      const result = await (window as any).stakingContract.methods
        .stake(selectedTokenIds.map((tokenId: string) => parseInt(tokenId, 10)))
        .send({
          from: user?.get('ethAddress')
        });

      console.log(result);
      setSelectedTokenIds([]);
      enqueueSnackbar('Stake Successful! Come back in ~10 minutes', {
        variant: 'success'
      });
      reload();
    } catch (err: any) {
      console.log(err);
      enqueueSnackbar(
        `Stake Unsuccessful: ${err.message ? err.message : err}`,
        {
          variant: 'error'
        }
      );
    } finally {
      setGlobalLoading(false);
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, tokenId: string) => {
    const selectedIndex = selectedTokenIds.indexOf(tokenId);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTokenIds, tokenId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTokenIds.slice(1));
    } else if (selectedIndex === selectedTokenIds.length - 1) {
      newSelected = newSelected.concat(selectedTokenIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTokenIds.slice(0, selectedIndex),
        selectedTokenIds.slice(selectedIndex + 1)
      );
    }

    setSelectedTokenIds(newSelected);
  };

  const isSelected = (tokenId: string) =>
    selectedTokenIds.indexOf(tokenId) !== -1;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = unstakedNfts.map((nft: any) => nft.token_id);
      setSelectedTokenIds(newSelecteds);
      return;
    }
    setSelectedTokenIds([]);
  };

  return (
    <Accordion elevation={5}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="Unstaked-content"
      >
        <CancelOutlinedIcon sx={{ color: 'red', mr: 1 }} />
        <Typography>
          Unstaked{' '}
          {loading ? (
            <CircularProgress size={16} />
          ) : (
            `(${unstakedNfts.length})`
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Alert severity="info">
          If you just unstaked, please check back in ~10 minutes to allow our
          site to update
        </Alert>
        <Grid container justifyContent="space-between" sx={{ mb: 3, mt: 3 }}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={
                    unstakedNfts.length > 0 &&
                    selectedTokenIds.length === unstakedNfts.length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all'
                  }}
                />
              }
              label="Select All"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={
                selectedTokenIds.length === 0 || state.isLoadingGlobally
              }
              onClick={handleStake}
            >
              Stake {selectedTokenIds.length}
            </Button>
          </Grid>
        </Grid>
        <NftContainer
          handleClick={handleClick}
          isSelected={isSelected}
          nfts={unstakedNfts}
          loading={loading}
        />
      </AccordionDetails>
    </Accordion>
  );
};
