import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { NftCard } from './NftCard';

interface IData {
  nfts: any[];
  handleClick: (event: React.MouseEvent<unknown>, tokenId: string) => void;
  isSelected: (tokenId: string) => boolean;
  loading: boolean;
}

export const NftContainer: React.FC<IData> = ({
  nfts,
  handleClick,
  isSelected,
  loading
}) => {
  return (
    <Grid container justifyContent="flex-start" spacing={2}>
      {loading ? (
        <Grid item>
          {' '}
          <CircularProgress />{' '}
        </Grid>
      ) : (
        <>
          {nfts.length === 0 && (
            <Grid item>
              <Typography variant="h2" component="p" textAlign="center">
                No Axolittles to Show
              </Typography>
            </Grid>
          )}{' '}
        </>
      )}
      {nfts.map((nft: any, idx: number) => (
        <Grid item key={idx}>
          <NftCard
            data={nft}
            handleClick={handleClick}
            isSelected={isSelected}
          />
        </Grid>
      ))}
    </Grid>
  );
};
