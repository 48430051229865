import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { memo, useEffect, useRef } from 'react';
import { useOnScreen } from 'src/components/hooks/useOnScreen';

export const Bubbles = memo(() => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery<boolean>(theme.breakpoints.down('md'));

  const ref = useRef<any>();
  const isVisible = useOnScreen(ref);

  const canvasImageUrl = `https://res.cloudinary.com/dpaucaa0u/image/upload/v1638356666/Axolittles/${Math.floor(
    Math.random() * 10000
  )}.png`;

  const image = new Image();
  image.src = canvasImageUrl;

  useEffect(() => {
    if (!isVisible) return;
    const canvas = canvasRef.current as HTMLCanvasElement;
    const ctx = canvas!.getContext('2d') as CanvasRenderingContext2D;

    const bubbles = [] as any;

    const bubbleCount = 25;
    const bubbleSpeed = 1.5;
    const popLines = 6;
    let popDistance = 40;
    const mouseOffset = {
      x: 0,
      y: 0
    };

    if (canvas) {
      canvas.width = canvas.parentElement!.clientWidth;
      canvas.height = canvas.parentElement!.clientHeight;

      // ---------------
      // Event Listeners
      // ---------------

      const mouseMove = (e: any) => {
        mouseOffset.x = e.offsetX;
        mouseOffset.y = e.offsetY;
      };
      canvas.addEventListener('mousemove', mouseMove);

      window.addEventListener('resize', function () {
        canvas.width = canvas.parentElement!.clientWidth;
        canvas.height = canvas.parentElement!.clientHeight;
      });
    }

    // ---------------
    // Event Listeners
    // ---------------

    function animate(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) {
      // ------------
      // Clear Canvas
      // ------------

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const imageSize = isMobile ? 200 : 280;
      ctx.drawImage(
        image,
        canvas.width - imageSize,
        canvas.height - imageSize,
        imageSize,
        imageSize
      );

      // ------------
      // Draw Bubbles
      // ------------

      ctx.beginPath();
      for (let i = 0; i < bubbles.length; i++) {
        // first num = distance between waves
        // second num = wave height
        // third num = move the center of the wave away from the edge
        bubbles[i].position.x =
          Math.sin(bubbles[i].count / bubbles[i].distanceBetweenWaves) * 50 +
          bubbles[i].xOff;
        bubbles[i].position.y = bubbles[i].count;
        bubbles[i].render();

        if (bubbles[i].count < 0 - bubbles[i].radius) {
          bubbles[i].count = canvas.height + bubbles[i].yOff;
        } else {
          bubbles[i].count -= bubbleSpeed;
        }
      }

      // ---------------
      // On Bubble Hover
      // ---------------

      for (let i = 0; i < bubbles.length; i++) {
        if (
          mouseOffset.x > bubbles[i].position.x - bubbles[i].radius &&
          mouseOffset.x < bubbles[i].position.x + bubbles[i].radius
        ) {
          if (
            mouseOffset.y > bubbles[i].position.y - bubbles[i].radius &&
            mouseOffset.y < bubbles[i].position.y + bubbles[i].radius
          ) {
            for (let a = 0; a < bubbles[i].lines.length; a++) {
              popDistance = bubbles[i].radius * 0.5;
              bubbles[i].lines[a].popping = true;
              bubbles[i].popping = true;
            }
          }
        }
      }

      window.requestAnimationFrame(() => animate(ctx, canvas));
    }

    window.requestAnimationFrame(() => animate(ctx, canvas));

    // ------------------
    // Bubble Constructor
    // ------------------

    const createBubble = function (
      this: any,
      ctx: CanvasRenderingContext2D,
      canvas: HTMLCanvasElement
    ) {
      this.position = { x: 0, y: 0 };
      this.radius = 8 + Math.random() * 6;
      this.xOff = Math.random() * canvas.width - this.radius;
      this.yOff = Math.random() * canvas.height;
      this.distanceBetweenWaves = 50 + Math.random() * 40;
      this.count = canvas.height + this.yOff;
      this.color = '#8bc9ee';
      this.lines = [];
      this.popping = false;
      this.maxRotation = 85;
      this.rotation =
        Math.floor(Math.random() * (this.maxRotation - this.maxRotation * -1)) +
        this.maxRotation * -1;
      this.rotationDirection = 'forward';

      // Populate Lines
      for (let i = 0; i < popLines; i++) {
        const tempLine = new (createLine as any)(ctx);
        tempLine.bubble = this;
        tempLine.index = i;

        this.lines.push(tempLine);
      }

      this.resetPosition = function () {
        this.position = { x: 0, y: 0 };
        this.radius = 8 + Math.random() * 6;
        this.xOff = Math.random() * canvas.width - this.radius;
        this.yOff = Math.random() * canvas.height;
        this.distanceBetweenWaves = 50 + Math.random() * 40;
        this.count = canvas.height + this.yOff;
        this.popping = false;
      };

      // Render the circles
      this.render = function () {
        if (this.rotationDirection === 'forward') {
          if (this.rotation < this.maxRotation) {
            this.rotation++;
          } else {
            this.rotationDirection = 'backward';
          }
        } else {
          if (this.rotation > this.maxRotation * -1) {
            this.rotation--;
          } else {
            this.rotationDirection = 'forward';
          }
        }

        ctx.save();
        ctx.translate(this.position.x, this.position.y);
        ctx.rotate((this.rotation * Math.PI) / 180);

        if (!this.popping) {
          ctx.beginPath();
          ctx.strokeStyle = '#8bc9ee';
          ctx.lineWidth = 1;
          ctx.arc(0, 0, this.radius - 3, 0, Math.PI * 1.5, true);
          ctx.stroke();

          ctx.beginPath();
          ctx.arc(0, 0, this.radius, 0, Math.PI * 2, false);
          ctx.stroke();
        }

        ctx.restore();

        // Draw the lines
        for (let a = 0; a < this.lines.length; a++) {
          if (this.lines[a].popping) {
            if (
              this.lines[a].lineLength < popDistance &&
              !this.lines[a].inversePop
            ) {
              this.lines[a].popDistance += 0.06;
            } else {
              if (this.lines[a].popDistance >= 0) {
                this.lines[a].inversePop = true;
                this.lines[a].popDistanceReturn += 1;
                this.lines[a].popDistance -= 0.03;
              } else {
                this.lines[a].resetValues();
                this.resetPosition();
              }
            }

            this.lines[a].updateValues();
            this.lines[a].render();
          }
        }
      };
    };

    // ----------------
    // Populate Bubbles
    // ----------------

    for (let i = 0; i < bubbleCount; i++) {
      const tempBubble = new (createBubble as any)(ctx, canvas);

      bubbles.push(tempBubble);
    }

    // ----------------
    // Line Constructor
    // ----------------

    function createLine(this: any, ctx: CanvasRenderingContext2D) {
      this.lineLength = 0;
      this.popDistance = 0;
      this.popDistanceReturn = 0;
      this.inversePop = false; // When the lines reach full length they need to shrink into the end position
      this.popping = false;

      this.resetValues = function () {
        this.lineLength = 0;
        this.popDistance = 0;
        this.popDistanceReturn = 0;
        this.inversePop = false;
        this.popping = false;

        this.updateValues();
      };

      this.updateValues = function () {
        this.x =
          this.bubble.position.x +
          (this.bubble.radius + this.popDistanceReturn) *
            Math.cos((2 * Math.PI * this.index) / this.bubble.lines.length);
        this.y =
          this.bubble.position.y +
          (this.bubble.radius + this.popDistanceReturn) *
            Math.sin((2 * Math.PI * this.index) / this.bubble.lines.length);
        this.lineLength = this.bubble.radius * this.popDistance;
        this.endX = this.lineLength;
        this.endY = this.lineLength;
      };

      this.render = function () {
        this.updateValues();

        ctx.beginPath();

        ctx.strokeStyle = '#8bc9ee';
        ctx.lineWidth = 2;
        ctx.moveTo(this.x, this.y);
        if (this.x < this.bubble.position.x) {
          this.endX = this.lineLength * -1;
        }
        if (this.y < this.bubble.position.y) {
          this.endY = this.lineLength * -1;
        }
        if (this.y === this.bubble.position.y) {
          this.endY = 0;
        }
        if (this.x === this.bubble.position.x) {
          this.endX = 0;
        }
        ctx.lineTo(this.x + this.endX, this.y + this.endY);
        ctx.stroke();
      };
    }
  }, [isVisible]);

  return (
    <Box
      ref={ref}
      sx={{
        width: '100%',
        height: 300,
        position: 'relative'
      }}
    >
      <Typography
        component="h1"
        color="textPrimary"
        sx={{
          fontFamily: 'Winkle',
          fontSize: isMobile ? 70 : 150,
          position: 'absolute',
          top: isMobile ? '20%' : '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 11,
          WebkitTextStrokeWidth: 3,
          WebkitTextStrokeColor: 'black',
          pointerEvents: 'none'
        }}
      >
        Staking
      </Typography>
      <canvas
        ref={canvasRef}
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'relative',
          background: 'linear-gradient(to top, #09f, #45d1ff)',
          // Outline does not work in Safari
          // outline: '3px solid white',
          boxShadow: '0 0 0 3px white',
          borderRadius: 10,

          zIndex: 10
        }}
      ></canvas>
    </Box>
  );
});
