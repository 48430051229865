import Grid from '@mui/material/Grid';
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { memo, useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';

const axolittlesContractAddress = process.env.REACT_APP_AXOLITTLES_CONTRACT!;

const stakingContractAddress = process.env.REACT_APP_STAKING_CONTRACT!;

const totalAxoNum = 10000;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'
  }
}));

export const Statistics = memo(() => {
  const [totalStaked, setTotalStaked] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { Moralis } = useMoralis();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) getTotalNftsStaked();

    return () => {
      isMounted = false;
    };
  }, []);

  const getTotalNftsStaked = async () => {
    try {
      setLoading(true);

      const result = await Moralis.Web3API.account.getNFTsForContract({
        chain: 'eth',
        address: stakingContractAddress,
        token_address: axolittlesContractAddress
      });
      const total = Number(result.total);
      setTotalStaked(total);
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <></>;

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item container justifyContent="center">
        <Grid item>
          <Typography
            color="textPrimary"
            variant="h3"
            textAlign="center"
            sx={{ mr: 1 }}
          >
            Total Axolittles Staked:
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="textPrimary" variant="h3" textAlign="center">
            {`${totalStaked} / ${totalAxoNum}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <BorderLinearProgress
          variant="determinate"
          color="primary"
          value={
            (Math.ceil(Number(totalStaked)) /
              (Math.ceil(Number(totalStaked) / totalAxoNum) * totalAxoNum)) *
            100
          }
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>
  );
});
