import { useMediaQuery } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React, { useContext, useState } from 'react';
import { useMoralis } from 'react-moralis';
import { ActionTypes, GlobalAppContext } from 'src/store';
import { NavContent } from './NavContent';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`
  }
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

interface IProps {
  children: any;
}

export const MainNavbar: React.FC<IProps> = ({ children }) => {
  const theme = useTheme();
  const { state, dispatch } = useContext(GlobalAppContext);
  const isMobile = useMediaQuery<boolean>(theme.breakpoints.down('md'));

  const [desktopDrawerOpen, setDrawerOpen] = useState(false);

  const { user } = useMoralis();

  const handleDarkModeChange = (payload: boolean) => {
    dispatch({
      type: ActionTypes.SET_IS_DARK_MODE,
      payload
    });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        color="default"
        sx={{ ...(!isMobile && { zIndex: theme.zIndex.drawer + 1 }) }}
        open={desktopDrawerOpen}
      >
        {state.isLoadingGlobally && <LinearProgress />}
        <Toolbar>
          <NavContent />

          {/* Toggle dark mode */}
          {/* <Typography
            color="textSecondary"
            variant="h2"
            component="p"
            sx={{ cursor: 'pointer', mr: 3 }}
            onClick={() => handleDarkModeChange(!state.isDarkMode)}
          >
            {state.isDarkMode ? <>🌝</> : <>🌚</>}
          </Typography> */}

          {/* Connect wallet button */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch({
                type: ActionTypes.SET_SHOW_CONNECT_WALLET_DIALOG,
                payload: true
              });
            }}
            sx={{ justifyContent: 'space-between', textTransform: 'none' }}
          >
            {user
              ? user.get('ethAddress').slice(0, 4) +
                '...' +
                user
                  .get('ethAddress')
                  .slice(
                    user.get('ethAddress').length - 4,
                    user.get('ethAddress').length
                  )
              : 'Connect'}
          </Button>
        </Toolbar>
      </AppBar>

      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
        {children}
      </Box>
    </Box>
  );
};
