export interface IState {
  isDarkMode: boolean;
  isWalletConnected: boolean;
  isLoadingGlobally: boolean;
  showConnectWalletDialog: boolean;
}

export const initialState: IState = {
  isDarkMode: true,
  isWalletConnected: false,
  isLoadingGlobally: false,
  showConnectWalletDialog: false
};

export enum ActionTypes {
  SET_IS_DARK_MODE = 'SET_IS_DARK_MODE',
  SET_IS_WALLET_CONNECTED = 'SET_IS_WALLET_CONNECTED',
  SET_IS_LOADING_GLOBALLY = 'SET_IS_LOADING_GLOBALLY',
  SET_SHOW_CONNECT_WALLET_DIALOG = 'SET_SHOW_CONNECT_WALLET_DIALOG'
}

interface ISetIsDarkMode {
  type: ActionTypes.SET_IS_DARK_MODE;
  payload: boolean;
}

interface ISetIsWalletConnected {
  type: ActionTypes.SET_IS_WALLET_CONNECTED;
  payload: boolean;
}
interface ISetLoadingGlobally {
  type: ActionTypes.SET_IS_LOADING_GLOBALLY;
  payload: boolean;
}

interface ISetShowConnectWalletDialog {
  type: ActionTypes.SET_SHOW_CONNECT_WALLET_DIALOG;
  payload: boolean;
}

export type ComponentActions =
  | ISetIsDarkMode
  | ISetIsWalletConnected
  | ISetLoadingGlobally
  | ISetShowConnectWalletDialog;

export const reducer: React.Reducer<IState, ComponentActions> = (
  state,
  action
) => {
  switch (action.type) {
    case ActionTypes.SET_IS_DARK_MODE:
      return {
        ...state,
        isDarkMode: action.payload
      };
    case ActionTypes.SET_IS_WALLET_CONNECTED:
      return { ...state, isWalletConnected: action.payload };
    case ActionTypes.SET_IS_LOADING_GLOBALLY:
      return { ...state, isLoadingGlobally: action.payload };
    case ActionTypes.SET_SHOW_CONNECT_WALLET_DIALOG:
      return { ...state, showConnectWalletDialog: action.payload };

    default:
      return state;
  }
};
