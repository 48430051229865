import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import React, { memo, useContext, useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';
import { ActionTypes, GlobalAppContext } from 'src/store';

interface IProps {
  stakedNfts: any[];
  reload: () => void;
  loading: boolean;
}

export const TotalEarned: React.FC<IProps> = memo(
  ({ stakedNfts, reload, loading }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery<boolean>(theme.breakpoints.down('md'));
    const [bubbles, setBubbles] = useState<number>(0);
    const { state, dispatch } = useContext(GlobalAppContext);

    const { enqueueSnackbar } = useSnackbar();

    const { Moralis, user } = useMoralis();

    // Have to convert to number[]
    const stakedTokenIds: number[] = stakedNfts.map((nft: any) =>
      parseInt(nft.token_id, 10)
    );

    useEffect(() => {
      let live = true;

      if (!loading) unclaimedBubbles(live);

      return () => {
        live = false;
      };
    }, [loading]);

    // Make get unclaimedBubbles every 10 seconds
    useEffect(() => {
      let live = true;
      let interval: any;

      if (stakedTokenIds.length !== 0) {
        interval = setInterval(async () => {
          unclaimedBubbles(live);
        }, 10000);
      }
      return () => {
        live = false;
        clearInterval(interval);
      };
    }, [stakedTokenIds]);

    const setGlobalLoading = (value: boolean) => {
      dispatch({
        type: ActionTypes.SET_IS_LOADING_GLOBALLY,
        payload: value
      });
    };

    const handleClaimBubbles = async () => {
      if (!user) {
        enqueueSnackbar('User is not logged in', { variant: 'error' });
        return;
      }

      try {
        setGlobalLoading(true);

        const result = await (window as any).stakingContract.methods
          .claim(stakedTokenIds)
          .send({
            from: user?.get('ethAddress')
          });

        console.log(result);
        enqueueSnackbar('Claim Successful! Come back in ~10 minutes', {
          variant: 'success'
        });
        reload();
      } catch (err: any) {
        console.error(err);
        enqueueSnackbar(
          `Claim Unsuccessful: ${err.message ? err.message : err}`,
          {
            variant: 'error'
          }
        );
      } finally {
        setGlobalLoading(false);
      }
    };

    const unclaimedBubbles = async (live: boolean) => {
      if (!live || stakedTokenIds.length === 0) return;
      const web3 = new Moralis.Web3();
      try {
        const result = await (window as any).stakingContract.methods
          .unclaimedRewards(stakedTokenIds)
          .call();

        let bn = web3.utils.toBN(0);

        result.forEach((val: any) => {
          bn = bn.add(web3.utils.toBN(val));
        });
        setBubbles(parseFloat(web3.utils.fromWei(bn)));
      } catch (err: any) {
        console.log(err);
        // enqueueSnackbar(
        //   `Error getting unclaimed $BUBBLES: ${err.message ? err.message : err}`,
        //   {
        //     variant: 'error'
        //   }
        // );
      }
    };

    return (
      <Card elevation={5}>
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography color="textPrimary" variant="h5">
                    Total $BUBBLES Earned
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    color="primary"
                    variant="h1"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {loading ? <CircularProgress /> : bubbles}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ ...(isMobile && { margin: 'auto' }) }}>
              <Grid container direction="column">
                <Grid item>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleClaimBubbles}
                    disabled={!bubbles || state.isLoadingGlobally}
                  >
                    Claim $BUBBLES
                  </Button>
                </Grid>
                {/* <Grid item>
                <Button variant="contained" fullWidth>
                  Claim $BUBBLES and Unstake
                </Button>
              </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
);
