import { Typography } from '@mui/material';
import React from 'react';

export const Header = () => {
  return (
    <>
      <Typography
        component="h2"
        variant="h3"
        color="textPrimary"
        textAlign="center"
        sx={{ fontWeight: 500, mb: 3 }}
      >
        Stake your Axolittles to earn $BUBBLES!
      </Typography>
    </>
  );
};
