import { useTheme } from '@mui/material/';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';
import AxolittlesAbi from 'src/assets/builds/axolittles.abi.json';
import StakingAbi from 'src/assets/builds/staking.abi.json';
import { Bubbles } from './components/Bubbles';
import { Header } from './components/Header';
import { Staked } from './components/Staked';
import { Statistics } from './components/Statistics';
import { TotalEarned } from './components/TotalEarned';
import { Unstaked } from './components/Unstaked';

export const StakingLayout = () => {
  const theme = useTheme();
  const { Moralis, user } = useMoralis();
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const [stakedNfts, setStakedNfts] = useState<any[]>([]);
  const [unstakedNfts, setUnstakedNfts] = useState<any[]>([]);

  const axolittlesContractAddress = process.env.REACT_APP_AXOLITTLES_CONTRACT!;

  const stakingContractAddress = process.env.REACT_APP_STAKING_CONTRACT!;

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await Promise.all([
        loadContractInstances(),
        getStakedNfts(),
        getUnstakedNfts()
      ]);

      setLoading(false);
    };
    load();
  }, []);

  const reload = async () => {
    setLoading(true);
    await Promise.all([getStakedNfts(), getUnstakedNfts()]);
    setLoading(false);
  };

  const loadContractInstances = async () => {
    try {
      // Attaching the Moralis web3 object to window so it can be accessed throughout the site
      (window as any).web3 = await Moralis.Web3.enableWeb3();

      const axolittesContract = await new (window as any).web3.eth.Contract(
        AxolittlesAbi as any,
        axolittlesContractAddress
      );
      const stakingContract = await new (window as any).web3.eth.Contract(
        StakingAbi as any,
        stakingContractAddress
      );

      // Attaching the contracts to the window object so they can be accessed throughout the site
      (window as any).axolittesContract = axolittesContract;
      (window as any).stakingContract = stakingContract;

      const isApprovedResponse = await axolittesContract.methods
        .isApprovedForAll(user?.get('ethAddress'), stakingContractAddress)
        .call();

      setIsApproved(isApprovedResponse);

      enqueueSnackbar('Connected', { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(`12: ${err.message ? err.message : err}`, {
        variant: 'error'
      });
    } finally {
    }
  };

  const getStakedNfts = async () => {
    try {
      const walletAddress = user?.get('ethAddress');
      const stakedTokenIds = await Moralis.Cloud.run('getStakedNfts', {
        walletAddress
      });

      // The reason we are doing this is because the staking events
      // don't have a way to determine if the user unstaked or not
      // so we have to do an additional check.
      const validStakedTokenIds: string[] = await Promise.all(
        stakedTokenIds.map(async (tokenId: string) =>
          (window as any).stakingContract.methods
            .stakeOwner(parseInt(tokenId, 10))
            .call()
            .then((stakingOwner: string) => {
              // If the stake owner matches the current user address, then it has not been unstaked
              if (stakingOwner.toLowerCase() === walletAddress.toLowerCase())
                return tokenId;
            })
        )
      );
      // Now that we have valid token ids for staked axos, we need to get their metadata
      const stakedTokensMetadata = await Promise.all(
        validStakedTokenIds.map((tokenId: string) =>
          Moralis.Web3API.token.getTokenIdMetadata({
            chain: 'eth',
            address: axolittlesContractAddress,
            token_id: tokenId
          })
        )
      );

      setStakedNfts(stakedTokensMetadata);
    } catch (err: any) {
      enqueueSnackbar(
        `Error loading staked Axolittles: ${err.message ? err.message : err}`,
        {
          variant: 'error'
        }
      );
    } finally {
    }
  };

  const getUnstakedNfts = async () => {
    try {
      // Query for unstaked axolittles (axolittles in your account).
      // This will only query the first 500!
      const unstakedTokensMetadata =
        await Moralis.Web3API.account.getNFTsForContract({
          chain: 'eth',
          address: user?.get('ethAddress'),
          token_address: axolittlesContractAddress
        });

      const result = unstakedTokensMetadata.result as any[];

      setUnstakedNfts(result);
    } catch (err: any) {
      enqueueSnackbar(
        `Error loading unstaked Axolittles: ${err.message ? err.message : err}`,
        {
          variant: 'error'
        }
      );
    } finally {
    }
  };

  const setApproval = async () => {
    if (isApproved) return;
    await (window as any).axolittesContract.methods
      .setApprovalForAll(stakingContractAddress, true)
      .send({
        from: user?.get('ethAddress')
      })
      .then((result: any) => {
        console.log(result);
        enqueueSnackbar('Approval Successful!', { variant: 'success' });
        setIsApproved(true);
      })
      .catch((err: any) => {
        console.log(err);
        enqueueSnackbar(
          `Approval Unsuccessful!: ${err.message ? err.message : err}`,
          {
            variant: 'error'
          }
        );
      });
  };

  return (
    <Container
      maxWidth={'lg'}
      sx={{
        // margin: `${theme.spacing(3)}px auto`,
        height: '100%',
        pb: 3
      }}
    >
      <Grid container direction="column" spacing={3} sx={{ mt: 3 }}>
        <Grid item>
          <Header />
        </Grid>
        <Grid item>
          <Statistics />
        </Grid>
        <Grid item>
          <Bubbles />
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={{ maxWidth: 'unset' }}>
          <TotalEarned
            reload={reload}
            stakedNfts={stakedNfts}
            loading={loading}
          />
        </Grid>
        <Grid item>
          <Staked
            setApproval={setApproval}
            reload={reload}
            stakedNfts={stakedNfts}
            loading={loading}
          />
        </Grid>
        <Grid item>
          <Unstaked
            setApproval={setApproval}
            reload={reload}
            unstakedNfts={unstakedNfts}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
